module services {
    export module master {
        export class statusService implements interfaces.master.IStatusService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of next statuses for a dropdown/autocomplete list
            getNextStatusList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Status/GetNextStatusList", {
                        currentStatusId: '@currentStatusId'
                    }, {
                            query: {
                                method: 'GET',
                                isArray: true
                            }
                        });
            }

            //Gets list of next statuses for a dropdown/autocomplete list
            getTransactionStatusHistory(): ng.resource.IResourceClass<interfaces.master.ITransactionStatusHistory> {

                return this.$resource<interfaces.master.ITransactionStatusHistory>(this.ENV.DSP_URL + "Status/GetTransactionStatusHistory", {
                    StatusTypeClassificationValueId: '@StatusTypeClassificationValueId',
                    TransactionId: '@TransactionId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            //Gets list of statuses for a dropdown
            getStatusDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Status/GetStatusDropdownList", {
                    type: '@type'
                });
            }

            getStatusAuthorisationUsers(referenceTypeId: number, referenceId: number): ng.resource.IResourceClass<interfaces.master.IStatusAuthorisationUser> {
                return this.$resource<interfaces.master.IStatusAuthorisationUser>(this.ENV.DSP_URL + "Status/GetStatusAuthorisationUsers", {
                    referenceTypeId: referenceTypeId,
                    referenceId: referenceId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getAvailableAuthorisingUsers(statusAuthorisationLogId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Status/GetAvailableAuthorisingUsers", {
                    statusAuthorisationLogId: statusAuthorisationLogId
                });
            }

            changePrincipleUser(statusAuthorisationLogId: number, newUserId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Status/changePrincipleUser",
                    {
                        statusAuthorisationLogId: statusAuthorisationLogId,
                        newUserId: newUserId
                    });
            }

            getAuthorisationDashBoard(): ng.resource.IResourceClass<interfaces.master.IAuthorisationDashBoard> {
                return this.$resource<interfaces.master.IAuthorisationDashBoard>(this.ENV.DSP_URL + "Status/GetAuthorisationDashBoard", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

        }
    }
    angular.module("app").service("statusService", services.master.statusService);
}